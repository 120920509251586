import './App.css';
import yourBusinessLogo from './your-business-logo.svg';
//import ContactForm from "./ContactForm";
import RatingPanel from "./RatingPanel";
import { useParams } from "react-router-dom";
import tenants from "./tenants.json";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  const { tenantid } = useParams()
  const { locationid } = useParams()

  let logo = yourBusinessLogo;
  let rotate = true;
  let title = t('Your Business');
  let hide_branding = false;

  let tenant_name = "Demo";
  let location_name = "Demo";

  let location_website = "https://happycustomer360.pt/";
  
  let location_links = [{ key: "google", url: "https://g.page/r/CbsBDirkEQIhEBM/review"}]

  let tenant = undefined;
  let location = undefined;

  tenant = tenants.find(item => item.id === tenantid);

  console.log("Tenent:")
  console.log(JSON.stringify(tenant))

  if (tenant !== undefined)
  {
    location = tenant.locations.find(item => item.id === locationid)

    logo = tenant.logo;
    title = tenant.name;
    hide_branding = tenant.hideBranding;

    tenant_name = tenant.name;
    location_website = tenant.website;

    location_links = [];
    if (tenant.links.google !== undefined) {
      location_links.push({ key: "google", url: tenant.links.google});
    }
    if (tenant.links.tripadvisor !== undefined) {
      location_links.push({ key: "tripadvisor", url: tenant.links.tripadvisor});
    }
  }

  console.log("Location:")
  console.log(JSON.stringify(location))

  if (tenant !== undefined && location !== undefined)
  {
    if(location.addToTitle)
    {
      title = title + " - " + location.name;
    }
    if(location.replaceTitle)
    {
      title = location.name;
    }
    rotate = false;
    location_name = location.name;
  }

  document.title = "Reviews | " + title;
  document.body.style.backgroundColor = "black"

  return (
    <div className="container mx-auto min-h-screen flex flex-col text-center text-white/90">

      <header className="mt-8 mb-2">
        <img src={logo} className={`h-20 mx-auto m-1 ${rotate ? "ratating-logo" : ""}`} alt={tenant_name}
             onError={({ currentTarget }) => { currentTarget.src=yourBusinessLogo; }}
        />
        <p className="">{title}</p>
      </header>

      <main className="flex flex-col flex-1 justify-center">
        <RatingPanel tenant={tenant_name} location={location_name} website={location_website} links={location_links} />
      </main>

      <footer className="my-2">
        <p className={`text-xs font-thin ${hide_branding ? "invisible" : ""}`}>{t('Powered by')} <a className="App-link" href="https://happycustomer360.pt/" target="_blank" rel="noopener noreferrer author">Happy Customer 360</a></p>
      </footer>

    </div>
  );
}

export default App;
