import anguishEmoji from './emoji-animation/anguished.webp';
import sadEmoji from './emoji-animation/sad.webp';
import neutralEmoji from './emoji-animation/neutral.webp';
import smileEmoji from './emoji-animation/smile.webp';
import grinningEmoji from './emoji-animation/grinning.webp';

import anguishEmojiPlaceholder from './emoji-animation/anguished.png';
import sadEmojiPlaceholder from './emoji-animation/sad.png';
import neutralEmojiPlaceholder from './emoji-animation/neutral.png';
import smileEmojiPlaceholder from './emoji-animation/smile.png';
import grinningEmojiPlaceholder from './emoji-animation/grinning.png';

import { useTranslation } from "react-i18next";
import ProgressiveImage from "react-progressive-graceful-image";

/* 
  https://googlefonts.github.io/noto-emoji-animation/
*/

export default function EmojiButton({ value, onButtonClick }) {
  const { t } = useTranslation();

  let imageSrc = '';
  let imagePlaceholderSrc = '';
  let text = '';

  if (value === -2) {
    imageSrc = anguishEmoji;
    imagePlaceholderSrc = anguishEmojiPlaceholder;
    text = t('Very Poor');
  } else if (value === -1) {
    imageSrc = sadEmoji;
    imagePlaceholderSrc = sadEmojiPlaceholder;
    text = t('Poor');
  } else if (value === 0) {
    imageSrc = neutralEmoji;
    imagePlaceholderSrc = neutralEmojiPlaceholder;
    text = t('Average');
  } else if (value === 1) {
    imageSrc = smileEmoji;
    imagePlaceholderSrc = smileEmojiPlaceholder;
    text = t('Good');
  } else if (value === 2) {
    imageSrc = grinningEmoji;
    imagePlaceholderSrc = grinningEmojiPlaceholder;
    text = t('Excellent');
  }

  return (
    <button className="self-start m-4" onClick={onButtonClick}>
      <figure className="">
        {/* <img src={imageSrc} className="mb-2" alt={`Rate ${value}.`} /> */}
        <ProgressiveImage src={imageSrc} placeholder={imagePlaceholderSrc}>
          {(src) => <img src={src} alt={`Rate ${value}.`} className="mb-2" />}
        </ProgressiveImage>
        <figcaption className="text-xs md:text-sm">{text}</figcaption>
      </figure>
    </button>
  );
}
