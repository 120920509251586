import CtaButton from "./CtaButton";
import { useTranslation } from "react-i18next";

export default function CtaButtonList({ ctaList, onButtonClick }) {
  const { t } = useTranslation();

  async function handleExternalClick(value) {
    onButtonClick(value);
  };

  return (
    <div className="flex flex-row space-x-3">
      {ctaList.map((cta) => {
        return (
          <CtaButton key={cta.key} link={cta.url} icon={cta.key} text={t(cta.key)} onButtonClick={() => handleExternalClick(cta.key)} />
        );
      })}
    </div>
  );
}
