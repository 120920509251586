import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.initialize("G-4LXKPQ517V", {
  gtagOptions: {
    custom_map: {
      dimension1: "tenant",
      dimension2: "location",
      metric1: "rating",
      metric2: "positive",
      metric3: "negative"
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/tenant/:tenantid/location/:locationid" element={<App />} />
        <Route path='*' element={<App />}/>
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
