import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  pt: {
    translation: {
      "Your Business": "Demonstração",
      "How do you rate us?": "Como é que avalia o nosso serviço?",
      "Very Poor": "Muito Mau",
      "Poor": "Mau",
      "Average": "Médio",
      "Good": "Bom",
      "Excellent": "Excelente",
      "Thank you!": "Obrigado por entrar em contato e nos fornecer um feedback valioso!",
      "We're sorry": "Lamentamos saber que não teve uma boa experiência com nosso serviço.",
      "We're happy": "Estamos muito felizes! Obrigado por nos fornecer um feedback valioso.",
      "Please share": "Por favor, partilhe esta experiência com os seus amigos!",
      "Contact us": "Por favor, não hesite em falar conosco para expor algum problema.",
      "Contacts": "Contactos",
      "google": "Avaliações Google",
      "tripadvisor": "Avaliações Tripadvisor",
      "Powered by": "Com tecnologia"
    }
  },
  en: {
    translation: {
      "Your Business": "My Business",
      "How do you rate us?": "How do you rate our service?",
      "Very Poor": "Very Poor",
      "Poor": "Poor",
      "Average": "Average",
      "Good": "Good",
      "Excellent": "Excellent",
      "Thank you!": "Thank you for reaching out and providing us with valuable feedback!",
      "We're sorry": "We're sorry to hear that you didn't have a great experience with our service.",
      "We're happy": "We're so happy to hear from you! Thank you for your valuable feedback.",
      "Please share": "Please share this review with your friends!",
      "Contact us": "Please don't hesitate to contact us if you want to discuss this further.",
      "Contacts": "Contacts",
      "google": "Google Reviews",
      "tripadvisor": "Tripadvisor Reviews",
      "Powered by": "Powered by"
    }
  }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: "pt", 
    fallbackLng: "pt",
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;