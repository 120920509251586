import './CtaButton.css';
import websiteIcon from './icons/website.png';
import googleIcon from './icons/google.png';
import tripadvisorIcon from './icons/tripadvisor.svg';

export default function CtaButton({ link, icon, text, onButtonClick }) {

  let iconSrc = websiteIcon;

  if (icon === "google") {
    iconSrc = googleIcon;
  }

  if (icon === "tripadvisor") {
    iconSrc = tripadvisorIcon;
  }

  if (link === undefined) {
    return (
      <></>
    )
  }

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <button className="App-button flex items-center" onClick={onButtonClick}>
        <img src={iconSrc} className="icon h-8 w-8" alt={text} />
        <span className="text-xs ml-1">{text}</span>
      </button>
    </a>
  );
}
