import React, { useState } from "react";
//import EmoticonButton from "./EmoticonButton";
import EmojiButton from "./EmojiButton";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import worriedEmoji from './emoji-animation/worried.webp';
import partyEmoji from './emoji-animation/party.gif';
import thankYouEmoji from './emoji-animation/thank-you.gif';
import CtaButtonList from "./CtaButtonList";

const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

export default function RatingPanel({ tenant, location, website, links }) {
  const [rating, setRating] = useState();
  const { t } = useTranslation();

  async function handleClick(value) {

    console.log("RatingPanel Click value: %d", value);

    setRating(value);
    // TODO: Check why rating/setRating is not working!
    console.log("Rating (after setRating with value)", rating);

    /*
    // GA4 post_review custom event
    */

    const postReviewParameters = {
      // custom dimensions
      tenant: tenant,
      location: location,
      // custom metrics
      rating: value,
      positive: (value > 0),
      negative: (value < 0)
    };

    console.log("GA4 Event post_review" + JSON.stringify(postReviewParameters));

    // Fire event
    ReactGA.event("post_review", postReviewParameters);

    // Wait and reset
    await sleep(10000);
    setRating(undefined);
  }

  async function handleExternalClick(value) {
    console.log("RatingPanel ExternalClick value: %s", value);

    if(value === "website")
    {
      /*
      // GA4 click enhanced measurement event
      // (If configured, is automatically collected with GA4 Enhanced measurement!)
      */
      const clickParameters = {
        // click enhanced measurement event parameters
        link_id: value,
        //link_url: ,
        //link_domain: ,
        // custom dimensions
        tenant: tenant,
        location: location,
        // custom metrics
        rating: rating,
      };

      console.log("GA4 Event click" + JSON.stringify(clickParameters));

      // Fire event
      ReactGA.event("click", clickParameters);
    }
    else
    {
      /*
      // GA4 share recommended event
      */

      const shareParameters = {
        // share recommended event parameters
        method: value,
        // custom dimensions
        tenant: tenant,
        location: location,
        // custom metrics
        rating: rating
      };

      console.log("GA4 Event share" + JSON.stringify(shareParameters));

      // Fire event
      ReactGA.event("share", shareParameters);
    }
  }

  if (rating !== undefined) {
    let emoji = thankYouEmoji;
    let message = t('Thank you!');
    let actionMessage = "";

    let cTAs = [];

    if(rating > 0)
    {
      message = t("We're happy");
      emoji = partyEmoji;
      actionMessage = t("Please share");

      cTAs = links;
    }
    else
    {
      cTAs = [{ key: "website", url: website}];

      if(rating < 0)
      {
        message = t("We're sorry");
        emoji = worriedEmoji;
        actionMessage = t("Contact us");
      }
    }

    return (
      <div className="flex flex-col mb-32">
        <img className="mb-2 h-32 w-32 mx-auto" src={emoji} alt={message} />
        <div className="text-4xl my-4 mx-4">{message}</div>
        <div className="text-xl my-4 mx-4">{actionMessage}</div>
        <div className="mx-auto">
          <CtaButtonList ctaList={cTAs} onButtonClick={e => handleExternalClick(e)}/>
        </div>
      </div>
    );
  }
  
  return (
    <div className="flex flex-col mb-32">
        <div className="text-6xl">{t('How do you rate us?')}</div>
        
        <div className="grid grid-cols-4 mt-8 md:mx-16 md:gap-8 lg:mx-32 lg:gap-16">
            <EmojiButton value={-2} onButtonClick={() => handleClick(-2)} />
            <EmojiButton value={-1} onButtonClick={() => handleClick(-1)} />
            {/* <EmojiButton value={0} onButtonClick={() => handleClick(0)} /> */}
            <EmojiButton value={1} onButtonClick={() => handleClick(1)} />
            <EmojiButton value={2} onButtonClick={() => handleClick(2)} />
        </div>
    </div>
  );
}
